import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import { DeviceId } from '../../models/Device';
import { GatewayId } from '../../models/Gateway';
import { type Owner, Site, SiteId } from '../../models/Site';

export class SiteDetailActions {
    static readonly LOAD_SITE_INFO = 'LOAD_SITE_INFO';
    static readonly LOAD_SITE_OWNER = 'LOAD_SITE_OWNER';
    static readonly ACCESS_GRANTED_SERVEGO = 'ACCESS_GRANTED_SERVEGO';
    static readonly REMOVE_SITE = 'REMOVE_SITE';
}

export const loadSiteInfo = createAction(
    SiteDetailActions.LOAD_SITE_INFO,
    // FIXME replace by a SiteInfo type, as there is a mapping between this entry and the final object
    props<{ site: Site }>(),
);

export const loadSiteOwner = createAction(SiteDetailActions.LOAD_SITE_OWNER, props<{ siteId: SiteId; owner: Owner }>());

export const accessGrantedServego = createAction(
    SiteDetailActions.ACCESS_GRANTED_SERVEGO,
    props<{ siteId: SiteId; isServego: boolean }>(),
);

export const removeSite = createAction(SiteDetailActions.REMOVE_SITE, props<{ siteId: SiteId }>());

export const SitePageActions = createActionGroup({
    source: 'Site Page',
    events: {
        opened: props<{ siteId: SiteId }>(),
        closed: emptyProps(),
        installationTabOpened: props<{ siteId: SiteId; deviceId: DeviceId }>(),
        devicesTabOpened: props<{ siteId: SiteId; deviceId: DeviceId }>(),
    },
});

export const SitePageLifeCycleActions = createActionGroup({
    source: 'Site Page Lifecycle',
    events: {
        defaultGatewaySelected: props<{ gatewayId: GatewayId }>(),
        gatewayDefaultDeviceSelected: props<{ deviceId: DeviceId }>(),
        deviceGatewaySelected: props<{ gatewayId: GatewayId }>(),
    },
});

export const SiteInstallationPageActions = createActionGroup({
    source: 'Site Installation Page',
    events: {
        gatewaySelected: props<{ gatewayId: GatewayId }>(),
        interventionRequested: props<{ siteId: SiteId }>(),
    },
});

export const SiteDevicesPageActions = createActionGroup({
    source: 'Site Devices Page',
    events: {
        gatewaySelected: props<{ gatewayId: GatewayId }>(),
        deviceSelected: props<{ deviceId: DeviceId }>(),
    },
});
