import { ChangeDetectorRef, Component, type OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getUiToasterMessages } from '../../../../site-detail/store/selectors/ui';
import { VideoCallToasterItemComponent } from '../../../../video-conference/components/video-call-toaster-item/video-call-toaster-item.component';
import { AutoDestroy } from '../../../annotations/autodestroy';
import { ToasterMessage } from '../../../models/Toaster';
import { AppState } from '../../../store/app-state';
import { AccessGrantedItemComponent } from '../access-granted-item/access-granted-item.component';
import { DeviceRefreshStateFailedItemComponent } from '../device-refresh-state-failed-item/device-refresh-state-failed-item.component';
import { DeviceUpdateItemComponent } from '../device-update-item/device-update-item.component';
import { ErrorItemComponent } from '../error/error-item.component';
import { MailReceivedItemComponent } from '../mail-received-item/mail-received-item.component';
import { MailSentItemComponent } from '../mail-sent-item/mail-sent-item.component';
import { ServegoChangedComponent } from '../servego-changed-item/servego-changed-item.component';
import { SiteDeviceErrorItemComponent } from '../site-device-error-item/site-device-error-item.component';
import { ToasterSuccessComponent } from '../toaster-success/toaster-success.component';

@Component({
    selector: 'app-toaster',
    templateUrl: './toaster.component.html',
    styleUrls: ['./toaster.component.scss'],
    standalone: true,
    imports: [
        DeviceUpdateItemComponent,
        AccessGrantedItemComponent,
        MailSentItemComponent,
        ServegoChangedComponent,
        DeviceRefreshStateFailedItemComponent,
        SiteDeviceErrorItemComponent,
        ErrorItemComponent,
        ToasterSuccessComponent,
        MailReceivedItemComponent,
        VideoCallToasterItemComponent,
    ],
})
export class ToasterComponent implements OnInit {
    @AutoDestroy componentDestroyed$ = new Subject<void>();

    readonly messages$: Observable<ToasterMessage[]>;
    messages: ToasterMessage[] = [];
    messagesToDisplay: ToasterMessage[] = [];

    constructor(
        private ref: ChangeDetectorRef,
        private store: Store<AppState>,
    ) {
        this.messages$ = this.store.select(getUiToasterMessages);
    }

    ngOnInit() {
        this.messages$.pipe(takeUntil(this.componentDestroyed$)).subscribe((messages) => {
            this.messages = messages;
            this.messagesToDisplay = this.messages.slice(0, 3);
            this.ref.detectChanges();
        });
    }
}
