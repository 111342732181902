export enum BUSINESS_UNIT {
    AUSTRALIA_NEW_ZEALAND = 'BU170',
    AUSTRIA = 'BU180',
    BALTICS = 'BU20',
    BELUX = 'BU190',
    CZECH_REPUBLIC = 'BU230',
    FRANCE = 'BU240',
    GCC = 'BU40',
    GERMANY = 'BU250',
    GREECE = 'BU260',
    HUNGARY = 'BU280',
    ISRAEL = 'BU310',
    ITALY = 'BU320',
    LEVANT = 'BU80',
    NAF = 'BU590',
    NETHERLANDS = 'BU360',
    NORWAY = 'BU530',
    POLAND = 'BU380',
    PORTUGAL = 'BU390',
    ROMANIA = 'BU510',
    SOUTH_AFRICA = 'BU600',
    SPAIN = 'BU420',
    SWEDEN = 'BU370',
    SWITZERLAND = 'BU430',
    TAI = 'BU610',
    UK_IRELAND = 'BU460',
    US = 'BU470',
}

export interface CountryConfig {
    // Displayed in BO (expect installateurs, Accès)
    value: string;
    code: string;
    // Used for page redirections
    language: string;
    bu: BUSINESS_UNIT;
    returnToSomfyProUrl: string;
    returnToSomfyProPreprodUrl: string;
    returnToContactPage: string;
}

interface BuConfig {
    value: BUSINESS_UNIT;
    viewValue: string;
}

export interface AvailableAppLocale {
    shortName: string;
    adminName: string;
    name: string;
}

export const DEFAULT_LANGUAGE = 'en';

export const countriesConfig: Readonly<CountryConfig[]> = [
    {
        value: 'ALBANIA',
        code: 'AL',
        language: 'sq',
        bu: BUSINESS_UNIT.HUNGARY,
        returnToSomfyProUrl: 'https://www.somfypro.com',
        returnToSomfyProPreprodUrl: 'https://www.somfypro.com',
        returnToContactPage: 'https://www.somfypro.com/contact-us',
    },
    {
        value: 'AUSTRALIA',
        code: 'AU',
        language: 'en',
        bu: BUSINESS_UNIT.AUSTRALIA_NEW_ZEALAND,
        returnToSomfyProUrl: 'https://www.somfypro.com.au',
        returnToSomfyProPreprodUrl: 'https://www.somfypro.com.au',
        returnToContactPage: 'https://www.somfypro.com.au/contact-us',
    },
    {
        value: 'AUSTRIA',
        code: 'AT',
        language: 'at',
        bu: BUSINESS_UNIT.AUSTRIA,
        returnToSomfyProUrl: 'https://www.somfynetz.at',
        returnToSomfyProPreprodUrl: 'https://www.somfynetz.at',
        returnToContactPage: 'https://www.somfy.at/hilfe-center',
    },
    {
        value: 'BELGIUM',
        code: 'BE',
        language: 'be',
        bu: BUSINESS_UNIT.BELUX,
        returnToSomfyProUrl: 'https://www.somfypro.be',
        returnToSomfyProPreprodUrl: 'https://www.somfypro.be',
        returnToContactPage: 'https://www.somfypro.be/contact',
    },
    {
        value: 'BULGARIA',
        code: 'BG',
        language: 'bg',
        bu: BUSINESS_UNIT.HUNGARY,
        returnToSomfyProUrl: 'https://www.somfypro.com',
        returnToSomfyProPreprodUrl: 'https://www.somfypro.com',
        returnToContactPage: 'https://www.somfypro.com/contact-us',
    },
    {
        value: 'CYPRUS',
        code: 'CY',
        language: 'el',
        bu: BUSINESS_UNIT.LEVANT,
        returnToSomfyProUrl: 'https://www.somfy.com.cy/el-cy',
        returnToSomfyProPreprodUrl: 'https://www.somfy.com.cy/el-cy',
        returnToContactPage: 'https://www.somfy.com.cy/el-cy/about-somfy/contact',
    },
    {
        value: 'CROATIA',
        code: 'HR',
        language: 'hr',
        bu: BUSINESS_UNIT.HUNGARY,
        returnToSomfyProUrl: 'https://www.somfypro.hr',
        returnToSomfyProPreprodUrl: 'https://www.somfypro.hr',
        returnToContactPage: 'https://www.somfypro.hr/contact-us',
    },
    {
        value: 'CZECH_REPUBLIC',
        code: 'CZ',
        language: 'cz',
        bu: BUSINESS_UNIT.CZECH_REPUBLIC,
        returnToSomfyProUrl: 'https://www.somfypro.cz',
        returnToSomfyProPreprodUrl: 'https://www.somfypro.cz',
        returnToContactPage: 'https://www.somfypro.cz/kontaktni-informace',
    },
    {
        value: 'DENMARK',
        code: 'DK',
        language: 'dk',
        bu: BUSINESS_UNIT.NETHERLANDS,
        returnToSomfyProUrl: 'https://www.somfynordic.dk',
        returnToSomfyProPreprodUrl: 'https://www.somfynordic.dk',
        returnToContactPage: 'https://www.somfynordic.dk/kontakt-os1',
    },
    {
        value: 'GERMANY',
        code: 'DE',
        language: 'de',
        bu: BUSINESS_UNIT.GERMANY,
        returnToSomfyProUrl: 'https://www.somfynetz.de',
        returnToSomfyProPreprodUrl: 'https://de-b2b.ppr.somfy.com/',
        returnToContactPage: 'https://www.somfy-pro.de/kontakte',
    },
    {
        value: 'EGYPT',
        code: 'EG',
        language: 'en',
        bu: BUSINESS_UNIT.LEVANT,
        returnToSomfyProUrl: 'https://www.somfy.com.eg/ar-eg',
        returnToSomfyProPreprodUrl: 'https://www.somfy.com.eg/ar-eg',
        returnToContactPage: 'https://www.somfy.com.eg/ar-eg/assistance/contact',
    },
    {
        value: 'ESTONIA',
        code: 'EE',
        language: 'ee',
        bu: BUSINESS_UNIT.BALTICS,
        returnToSomfyProUrl: 'https://www.somfybaltics.com/et-ee',
        returnToSomfyProPreprodUrl: 'https://www.somfybaltics.com/et-ee',
        returnToContactPage: 'https://www.somfybaltics.com/et-ee/tugi-abi/kontaktivorm',
    },
    {
        value: 'FINLAND',
        code: 'FI',
        language: 'fi',
        bu: BUSINESS_UNIT.NETHERLANDS,
        returnToSomfyProUrl: 'https://www.somfynordic.fi',
        returnToSomfyProPreprodUrl: 'https://www.somfynordic.fi',
        returnToContactPage: 'https://www.somfynordic.fi/why_choose_somfy',
    },
    {
        value: 'FRANCE',
        code: 'FR',
        language: 'fr',
        bu: BUSINESS_UNIT.FRANCE,
        returnToSomfyProUrl: 'https://www.somfypro.fr',
        returnToSomfyProPreprodUrl: 'https://fr-b2b.ppr.somfy.com/',
        returnToContactPage: 'https://www.somfypro.fr/contactez-nous',
    },
    {
        value: 'GREECE',
        code: 'GR',
        language: 'gr',
        bu: BUSINESS_UNIT.GREECE,
        returnToSomfyProUrl: 'https://www.somfypro.gr',
        returnToSomfyProPreprodUrl: 'https://www.somfypro.gr',
        returnToContactPage: 'https://www.somfy.gr/contact',
    },
    {
        value: 'HUNGARY',
        code: 'HU',
        language: 'hu',
        bu: BUSINESS_UNIT.HUNGARY,
        returnToSomfyProUrl: 'https://www.somfypro.hu',
        returnToSomfyProPreprodUrl: 'https://www.somfypro.hu',
        returnToContactPage: 'https://www.somfypro.hu/kapcsolat',
    },
    {
        value: 'IRELAND',
        code: 'IE',
        language: 'en-IE',
        bu: BUSINESS_UNIT.UK_IRELAND,
        returnToSomfyProUrl: 'https://www.somfypro.co.uk',
        returnToSomfyProPreprodUrl: 'https://www.somfypro.co.uk',
        returnToContactPage: 'https://www.somfypro.co.uk/contact-us',
    },
    {
        value: 'ISRAEL',
        code: 'IL',
        language: 'en',
        bu: BUSINESS_UNIT.ISRAEL,
        returnToSomfyProUrl: 'https://www.somfy.co.il/',
        returnToSomfyProPreprodUrl: 'https://www.somfy.co.il/',
        returnToContactPage: 'https://www.somfy.co.il/support/contact',
    },
    {
        value: 'ITALIE',
        code: 'IT',
        language: 'it',
        bu: BUSINESS_UNIT.ITALY,
        returnToSomfyProUrl: 'https://www.somfypro.it',
        returnToSomfyProPreprodUrl: 'https://www.somfypro.it',
        returnToContactPage: 'https://www.somfypro.it/contatti',
    },
    {
        value: 'JORDAN',
        code: 'JO',
        language: 'en',
        bu: BUSINESS_UNIT.LEVANT,
        returnToSomfyProUrl: 'https://www.somfy.jo',
        returnToSomfyProPreprodUrl: 'https://www.somfy.jo',
        returnToContactPage: 'https://www.somfy.jo/contact-us',
    },
    {
        value: 'KUWAIT',
        code: 'KW',
        language: 'en',
        bu: BUSINESS_UNIT.GCC,
        returnToSomfyProUrl: 'https://www.somfy.me/en-gc',
        returnToSomfyProPreprodUrl: 'https://www.somfy.me/en-gc',
        returnToContactPage: 'https://www.somfy.me/en-gc/support-and-assistance/contact-somfy',
    },
    {
        value: 'LATVIA',
        code: 'LV',
        language: 'lv',
        bu: BUSINESS_UNIT.BALTICS,
        returnToSomfyProUrl: 'https://www.somfybaltics.com/lv-lv',
        returnToSomfyProPreprodUrl: 'https://www.somfybaltics.com/lv-lv',
        returnToContactPage: 'https://www.somfybaltics.com/lv-lv/musu-atbalsts/tehniskais-atbalsts',
    },
    {
        value: 'LEBANON',
        code: 'LB',
        language: 'lb',
        bu: BUSINESS_UNIT.LEVANT,
        returnToSomfyProUrl: 'https://www.somfy.com.lb/en-lb',
        returnToSomfyProPreprodUrl: 'https://www.somfy.com.lb/en-lb',
        returnToContactPage: 'https://www.somfy.com.lb/en-lb/about-somfy/contact-us',
    },
    {
        value: 'LITHUANIA',
        code: 'LT',
        language: 'lt',
        bu: BUSINESS_UNIT.BALTICS,
        returnToSomfyProUrl: 'https://www.somfybaltics.com/lt-lt',
        returnToSomfyProPreprodUrl: 'https://www.somfybaltics.com/lt-lt',
        returnToContactPage: 'https://www.somfybaltics.com/lt-lt/pagalba/kontaktiniai-duomenys',
    },
    {
        value: 'LUXEMBOURG',
        code: 'LU',
        language: 'lu',
        bu: BUSINESS_UNIT.BELUX,
        returnToSomfyProUrl: 'https://www.somfypro.be',
        returnToSomfyProPreprodUrl: 'https://www.somfypro.be',
        returnToContactPage: 'https://www.somfypro.be/contact',
    },
    {
        value: 'MOROCCO',
        code: 'MA',
        language: 'en',
        bu: BUSINESS_UNIT.NAF,
        returnToSomfyProUrl: 'https://www.somfy.ma/fr-ma',
        returnToSomfyProPreprodUrl: 'https://www.somfy.ma/fr-ma',
        returnToContactPage: 'https://www.somfy.ma/fr-ma/assistance/informations-de-contact',
    },
    {
        value: 'NETHERLAND',
        code: 'NL',
        language: 'nl',
        bu: BUSINESS_UNIT.NETHERLANDS,
        returnToSomfyProUrl: 'https://www.somfypro.nl/',
        returnToSomfyProPreprodUrl: 'https://www.somfypro.nl/',
        returnToContactPage: 'https://www.somfypro.nl/algemene-vraag',
    },
    {
        value: 'NEW ZEALAND',
        code: 'NZ',
        language: 'en',
        bu: BUSINESS_UNIT.AUSTRALIA_NEW_ZEALAND,
        returnToSomfyProUrl: 'https://www.somfypro.com.au',
        returnToSomfyProPreprodUrl: 'https://www.somfypro.com.au',
        returnToContactPage: 'https://www.somfypro.com.au/contact-us',
    },
    {
        value: 'NORWAY',
        code: 'NO',
        language: 'no',
        bu: BUSINESS_UNIT.NORWAY,
        returnToSomfyProUrl: 'https://www.somfynordic.com',
        returnToSomfyProPreprodUrl: 'https://www.somfynordic.com',
        returnToContactPage: 'https://www.somfynordic.com/kontakt-oss1',
    },
    {
        value: 'POLAND',
        code: 'PL',
        language: 'pl',
        bu: BUSINESS_UNIT.POLAND,
        returnToSomfyProUrl: 'https://www.somfybiznes.pl',
        returnToSomfyProPreprodUrl: 'https://www.somfybiznes.pl',
        returnToContactPage: 'https://www.somfybiznes.pl/kontakt-z-somfy',
    },
    {
        value: 'PORTUGAL',
        code: 'PT',
        language: 'pt',
        bu: BUSINESS_UNIT.PORTUGAL,
        returnToSomfyProUrl: 'https://www.somfy-profissional.pt',
        returnToSomfyProPreprodUrl: 'https://www.somfy-profissional.pt',
        returnToContactPage: 'https://www.somfy-profissional.pt/contacto',
    },
    {
        value: 'QATAR',
        code: 'QA',
        language: 'en',
        bu: BUSINESS_UNIT.GCC,
        returnToSomfyProUrl: 'https://www.somfy.me/en-gc',
        returnToSomfyProPreprodUrl: 'https://www.somfy.me/en-gc',
        returnToContactPage: 'https://www.somfy.me/en-gc/support-and-assistance/contact-somfy',
    },
    {
        value: 'ROMANIE',
        code: 'RO',
        language: 'ro',
        bu: BUSINESS_UNIT.ROMANIA,
        returnToSomfyProUrl: 'https://www.somfypro.ro',
        returnToSomfyProPreprodUrl: 'https://www.somfypro.ro',
        returnToContactPage: 'https://www.somfypro.ro/contact',
    },
    {
        value: 'SAUDI ARABIA',
        code: 'SA',
        language: 'sa',
        bu: BUSINESS_UNIT.GCC,
        returnToSomfyProUrl: 'https://www.somfy.me/en-gc',
        returnToSomfyProPreprodUrl: 'https://www.somfy.me/en-gc',
        returnToContactPage: 'https://www.somfy.me/en-gc/support-and-assistance/contact-somfy',
    },
    {
        value: 'SERBIA',
        code: 'RS',
        language: 'rs',
        bu: BUSINESS_UNIT.HUNGARY,
        returnToSomfyProUrl: 'https://www.somfypro.rs',
        returnToSomfyProPreprodUrl: 'https://www.somfypro.rs',
        returnToContactPage: 'https://www.somfypro.rs/contact-us',
    },
    {
        value: 'SLOVAKIA',
        code: 'SK',
        language: 'sk',
        bu: BUSINESS_UNIT.CZECH_REPUBLIC,
        returnToSomfyProUrl: 'https://www.somfypro.cz',
        returnToSomfyProPreprodUrl: 'https://www.somfypro.cz',
        returnToContactPage: 'https://www.somfypro.cz/kontaktni-informace',
    },
    {
        value: 'SLOVENIA',
        code: 'SI',
        language: 'sl',
        bu: BUSINESS_UNIT.HUNGARY,
        returnToSomfyProUrl: 'https://www.somfypro.com',
        returnToSomfyProPreprodUrl: 'https://www.somfypro.com',
        returnToContactPage: 'https://www.somfypro.com/contact-us',
    },
    {
        value: 'SOUTH AFRICA',
        code: 'ZA',
        language: 'en',
        bu: BUSINESS_UNIT.SOUTH_AFRICA,
        returnToSomfyProUrl: 'https://www.somfy.co.za',
        returnToSomfyProPreprodUrl: 'https://www.somfy.co.za',
        returnToContactPage: 'https://www.somfy.co.za',
    },
    {
        value: 'SPAIN',
        code: 'ES',
        language: 'es',
        bu: BUSINESS_UNIT.SPAIN,
        returnToSomfyProUrl: 'https://www.somfy-profesional.es',
        returnToSomfyProPreprodUrl: 'https://www.somfy-profesional.es',
        returnToContactPage: 'https://www.somfy-profesional.es/contacto2',
    },
    {
        value: 'SWEDEN',
        code: 'SE',
        language: 'se',
        bu: BUSINESS_UNIT.SWEDEN,
        returnToSomfyProUrl: 'https://www.somfynordic.se',
        returnToSomfyProPreprodUrl: 'https://www.somfynordic.se',
        returnToContactPage: 'https://www.somfynordic.se/kontakta-oss',
    },
    {
        value: 'SWITZERLAND',
        code: 'CH',
        language: 'ch',
        bu: BUSINESS_UNIT.SWITZERLAND,
        returnToSomfyProUrl: 'https://www.somfypro.ch',
        returnToSomfyProPreprodUrl: 'https://ch-b2b.ppr.somfy.com/',
        returnToContactPage: 'https://www.somfypro.ch/contact',
    },
    {
        value: 'TUNISIA',
        code: 'TN',
        language: 'en',
        bu: BUSINESS_UNIT.NAF,
        returnToSomfyProUrl: 'https://www.somfy.com.tr',
        returnToSomfyProPreprodUrl: 'https://www.somfy.com.tr',
        returnToContactPage: 'https://www.somfy.com.tr/destek-ve-iletisim/iletisim-bilgileri',
    },
    {
        value: 'TURKEY',
        code: 'TR',
        language: 'tr',
        bu: BUSINESS_UNIT.TAI,
        returnToSomfyProUrl: 'https://www.somfy.com.tr',
        returnToSomfyProPreprodUrl: 'https://www.somfy.com.tr',
        returnToContactPage: 'https://www.somfy.com.tr/destek-ve-iletisim/iletisim-bilgileri',
    },
    {
        value: 'UNITED ARAB EMIRATES',
        code: 'AE',
        language: 'ae',
        bu: BUSINESS_UNIT.GCC,
        returnToSomfyProUrl: 'https://www.somfy.me/en-gc',
        returnToSomfyProPreprodUrl: 'https://www.somfy.me/en-gc',
        returnToContactPage: 'https://www.somfy.me/en-gc/support-and-assistance/contact-somfy',
    },
    {
        value: 'UK',
        code: 'GB',
        language: 'en',
        bu: BUSINESS_UNIT.UK_IRELAND,
        returnToSomfyProUrl: 'https://www.somfypro.co.uk',
        returnToSomfyProPreprodUrl: 'https://www.somfypro.co.uk',
        returnToContactPage: 'https://www.somfypro.co.uk/contact-us',
    },
    {
        value: 'USA',
        code: 'US',
        language: 'us',
        bu: BUSINESS_UNIT.US,
        returnToSomfyProUrl: 'https://www.somfypro.com',
        returnToSomfyProPreprodUrl: 'https://www.somfypro.com',
        returnToContactPage: 'https://www.somfysystems.com/discover-somfy/contact-us/service-support',
    },
];

export interface languageMapping {
    dbLang: string;
    frontLang: string;
}
// Some languages files are different in database. Mapping needed.

export const languageMapping: Readonly<languageMapping>[] = [
    { dbLang: 'cz', frontLang: 'cs' },
    { dbLang: 'rs', frontLang: 'sr' },
];

export const buList: Readonly<BuConfig[]> = [
    {
        value: BUSINESS_UNIT.AUSTRIA,
        viewValue: 'COUNTRY_AUSTRIA',
    },
    {
        value: BUSINESS_UNIT.AUSTRALIA_NEW_ZEALAND,
        viewValue: 'COUNTRY_AUSTRALIA_AND_NEW_ZEALAND',
    },
    {
        value: BUSINESS_UNIT.BALTICS,
        viewValue: 'COUNTRY_BALTICS',
    },
    {
        value: BUSINESS_UNIT.BELUX,
        viewValue: 'COUNTRY_BELUX',
    },
    {
        value: BUSINESS_UNIT.CZECH_REPUBLIC,
        viewValue: 'COUNTRY_CZECH_REPUBLIC',
    },
    {
        value: BUSINESS_UNIT.FRANCE,
        viewValue: 'COUNTRY_FRANCE',
    },
    {
        value: BUSINESS_UNIT.GERMANY,
        viewValue: 'COUNTRY_DEUTSCHLAND',
    },
    {
        value: BUSINESS_UNIT.GCC,
        viewValue: 'COUNTRY_GCC',
    },
    {
        value: BUSINESS_UNIT.GREECE,
        viewValue: 'COUNTRY_GREECE',
    },
    {
        value: BUSINESS_UNIT.HUNGARY,
        viewValue: 'COUNTRY_HUNGARY',
    },
    {
        value: BUSINESS_UNIT.ISRAEL,
        viewValue: 'COUNTRY_ISRAEL',
    },
    {
        value: BUSINESS_UNIT.ITALY,
        viewValue: 'COUNTRY_ITALIE',
    },
    {
        value: BUSINESS_UNIT.LEVANT,
        viewValue: 'COUNTRY_LEVANT',
    },
    {
        value: BUSINESS_UNIT.NAF,
        viewValue: 'COUNTRY_NAF',
    },
    {
        value: BUSINESS_UNIT.NETHERLANDS,
        viewValue: 'COUNTRY_NETHERLAND',
    },
    {
        value: BUSINESS_UNIT.NORWAY,
        viewValue: 'COUNTRY_NORWAY',
    },
    {
        value: BUSINESS_UNIT.POLAND,
        viewValue: 'COUNTRY_POLAND',
    },
    {
        value: BUSINESS_UNIT.PORTUGAL,
        viewValue: 'COUNTRY_PORTUGAL',
    },
    {
        value: BUSINESS_UNIT.ROMANIA,
        viewValue: 'COUNTRY_ROMANIE',
    },
    {
        value: BUSINESS_UNIT.SOUTH_AFRICA,
        viewValue: 'COUNTRY_SOUTH_AFRICA',
    },
    {
        value: BUSINESS_UNIT.SPAIN,
        viewValue: 'COUNTRY_ESPAGNE',
    },
    {
        value: BUSINESS_UNIT.SWEDEN,
        viewValue: 'COUNTRY_SWEDEN',
    },
    {
        value: BUSINESS_UNIT.SWITZERLAND,
        viewValue: 'COUNTRY_SWITZERLAND',
    },
    {
        value: BUSINESS_UNIT.TAI,
        viewValue: 'COUNTRY_TAI',
    },
    {
        value: BUSINESS_UNIT.UK_IRELAND,
        viewValue: 'COUNTRY_UK_IRELAND',
    },
    {
        value: BUSINESS_UNIT.US,
        viewValue: 'COUNTRY_US',
    },
];

export const availableAppLocale: Readonly<AvailableAppLocale[]> = [
    {
        shortName: 'au',
        adminName: 'Australia',
        name: 'Australia > English',
    },
    {
        shortName: 'hr',
        adminName: 'Croatia',
        name: 'Hrvatska > Hrvatski',
    },
    {
        shortName: 'el',
        adminName: 'Cyprus',
        name: 'Κύπρος > Ελληνικά',
    },
    {
        shortName: 'cs',
        adminName: 'Czech Republic',
        name: 'Česká republika > Ceština',
    },
    {
        shortName: 'da',
        adminName: 'Denmark',
        name: 'Danmark > Dansk',
    },
    {
        shortName: 'de',
        adminName: 'Deutschland',
        name: 'Deutschland > Deutsch',
    },
    {
        shortName: 'eg',
        adminName: 'Egypt',
        name: 'مصر > English',
    },
    {
        shortName: 'ee',
        adminName: 'Estonia',
        name: 'Eesti > English',
    },
    {
        shortName: 'fi',
        adminName: 'Finland',
        name: 'Suomi > Suomalainen',
    },
    {
        shortName: 'fr',
        adminName: 'France',
        name: 'France > Français',
    },
    {
        shortName: 'el',
        adminName: 'Greece',
        name: 'Ελλάδα > Ελληνικά',
    },
    {
        shortName: 'hu',
        adminName: 'Hungary',
        name: 'Magyarország > Magyar',
    },
    {
        shortName: 'il',
        adminName: 'Israël',
        name: 'יִשְׂרָאֵל > English',
    },
    {
        shortName: 'it',
        adminName: 'Italy',
        name: 'Italia > Italiano',
    },
    {
        shortName: 'jo',
        adminName: 'Jordan',
        name: 'الأردن > English',
    },
    {
        shortName: 'kw',
        adminName: 'Kuwait',
        name: 'الكويت > English',
    },
    {
        shortName: 'lv',
        adminName: 'Latvia',
        name: 'Latvija > English',
    },
    {
        shortName: 'lb',
        adminName: 'Lebanon',
        name: 'لبنان > English',
    },
    {
        shortName: 'lt',
        adminName: 'Lithuania',
        name: 'Lietuva > English',
    },
    {
        shortName: 'ma',
        adminName: 'Morocco',
        name: 'المملكة المغربية > English',
    },
    {
        shortName: 'nl',
        adminName: 'Nederland',
        name: 'Nederland > Nederlands',
    },
    {
        shortName: 'nz',
        adminName: 'New Zealand',
        name: 'New Zealand > English',
    },
    {
        shortName: 'nn',
        adminName: 'Norway',
        name: 'Norge > Norsk',
    },
    {
        shortName: 'pl',
        adminName: 'Poland',
        name: 'Polska > Polski',
    },
    {
        shortName: 'pt',
        adminName: 'Portugal',
        name: 'Portugal > Português',
    },
    {
        shortName: 'qa',
        adminName: 'Qatar',
        name: 'دولة قطر > English',
    },
    {
        shortName: 'ro',
        adminName: 'Romania',
        name: 'România > Română',
    },
    {
        shortName: 'sa',
        adminName: 'Saudi Arabia',
        name: 'العربية السعودية > English',
    },
    {
        shortName: 'sr',
        adminName: 'Serbia',
        name: 'Srbija > Srpski',
    },
    {
        shortName: 'sk',
        adminName: 'Slovakia',
        name: 'Slovensko > Slovenský',
    },
    {
        shortName: 'za',
        adminName: 'South Africa',
        name: 'South Africa > English',
    },
    {
        shortName: 'es',
        adminName: 'Spain',
        name: 'España > Español',
    },
    {
        shortName: 'sv',
        adminName: 'Sweden',
        name: 'Sverige > Svenska',
    },
    {
        shortName: 'tn',
        adminName: 'Tunisia',
        name: 'تونس > English',
    },
    {
        shortName: 'tr',
        adminName: 'Türkiye',
        name: 'Türkiye > Türkçe',
    },
    {
        shortName: 'ae',
        adminName: 'United Arab Emirates',
        name: 'الإمارات العربية المتحدة > English',
    },
    {
        shortName: 'en',
        adminName: 'United Kingdom',
        name: 'United Kingdom > English',
    },
    {
        shortName: 'en-us',
        adminName: 'United States of America',
        name: 'United States of America > English US',
    },
];
