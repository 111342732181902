<div class="toaster-root-container">
  @for (message of messagesToDisplay; track message.id) {
    <div class="no-padding">
      <div class="toaster-message-container">
        @switch (message.type) {
          @case ('DEVICE_UPDATE_STATE') {
            <app-device-update-item [content]="message.content" [id]="message.id"></app-device-update-item>
          }
          @case ('ACCESS_GRANTED') {
            <app-access-granted-item [content]="message.content" [id]="message.id"></app-access-granted-item>
          }
          @case ('INTERVENTION_REQUEST_SENT' || message.type === 'SERVEGO_REQUEST_SENT' || message.type === 'SERVEGO_DEL_SENT' || message.type === 'SERVEGO_DELETE_SITE_INSTALLER_SENT') {
            <app-mail-sent-item [content]="message.content" [id]="message.id"></app-mail-sent-item>
          }
          @case ('SERVEGO_ACCESS_CHANGED') {
            <app-servego-changed-item [content]="message.content" [id]="message.id"></app-servego-changed-item>
          }
          @case ('DEVICE_REFRESH_STATE_FAILED') {
            <app-device-refresh-state-failed-item [content]="message.content"
                                                  [id]="message.id"></app-device-refresh-state-failed-item>
          }
          @case ('SITE_DEVICE_REQUEST_ERROR') {
            <app-site-device-error-item [content]="message.content" [id]="message.id"></app-site-device-error-item>
          }
          @case ('ERROR') {
            <app-error-item [content]="message.content" [id]="message.id"
                            [duration]="message.duration"></app-error-item>
          }
          @case ('CONFIRM_SUCCESS') {
            <app-toaster-success [content]="message.content" [id]="message.id"
                                 [duration]="message.duration"></app-toaster-success>
          }
          @case ('INSTALLER_RECEIVE_NEW_MESSAGE') {
            <app-mail-received-item [content]="message.content" [id]="message.id"
                                    [duration]="message.duration"></app-mail-received-item>
          }
          @case ('GUEST_JOINED_VIDEO_CALL') {
            <app-video-call-toaster-item [content]="message.content" [messageId]="message.id"></app-video-call-toaster-item>
          }
          @default {
          }
        }
      </div>
    </div>
  }
</div>
