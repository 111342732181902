import { AdviceNoticeRule } from '../../../models/UiProfile';

export default function resolveNotice(noticeRule: AdviceNoticeRule, value: number | string) {
    if (typeof noticeRule === 'string') {
        return noticeRule;
    }

    const matchedPattern = Object.keys(noticeRule).find((valuePattern) => {
        if (valuePattern.includes('-')) {
            const rangeExpr = valuePattern.replace('-', `<=${value} && ${value}<=`);
            return eval(rangeExpr);
        }

        if (valuePattern.includes('<') || valuePattern.includes('>')) {
            const conditionExpr = valuePattern.replace(/^([><])/, `${value}$1`);
            return eval(conditionExpr);
        }

        return valuePattern === value;
    });

    return matchedPattern ? noticeRule[matchedPattern] : null;
}
