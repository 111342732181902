import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SiteId } from '../../site-detail/models/Site';
import { VideoCall } from '../models/video-call';
import { VideoCallGateway } from './VideoCallGateway';

@Injectable()
export class StartVideoCallUseCase {
    constructor(@Inject('VideoCallGateway') private videoCallGateway: VideoCallGateway) {}

    exec(siteId: SiteId): Observable<VideoCall> {
        return this.videoCallGateway.startVideoCall(siteId);
    }
}
