import { CompanyId } from '../../alerts/models/Alert';
import { DeviceMS } from '../../site-detail/models/Device';
import { ExecutionStatus } from '../../site-detail/models/Execution';
import { SiteId } from '../../site-detail/models/Site';
import { VideoCallId } from '../../video-conference/models/video-call';
import { VIDEO_CALL_EVENT_TYPE } from '../../video-conference/models/video-call-event';
import { Timestamp } from '../common.types';
import { UserId } from './User';

export enum Event {
    CONNECT = 'connect',
    DISCONNECT = 'disconnect',
}

export enum Actions {
    MESSAGE = 'message',
    SET_USER_ID = 'set_user_id',
    SET_EXECUTIONS = 'set_executions',
    DEVICE_STATE_UPDATE = 'device_state_update',
    ACCESS_UPDATE = 'access_update',
    INTERVENTION_ACCESS_GRANTED = 'intervention_access_granted',
    INTERVENTION_ACTION = 'intervention_action',
    SERVEGO_ACCESS_GRANTED = 'servego_access_granted',
    EXECUTION_STATUS_UPDATE = 'execution_status_update',
    NEW_INSTALLER_MESSAGE = 'new_installer_message',
    SERVEGO_ACCESS_CHANGED = 'servego_access_changed',
    DEVICE_CREATED = 'device_created',
    DEVICE_REMOVED = 'device_removed',
    DEVICE_LABEL_UPDATE = 'device_label_update',
    VIDEO_CALL_CREATED = 'video_call_created',
    JOINED_PARTICIPANT = 'joined_participant',
    LEFT_PARTICIPANT = 'left_participant',
    LEAVE_CALL = 'leave_call',
}

export enum UISocketMessageType {
    TEXT = 'text',
    DATA = 'data',
}

export interface SetUserIdMessage {
    userId: string;
}

export interface UISocketMessage {
    type: UISocketMessageType;
    message: any;
}

export interface UISocketDeviceStateUpdateMessage {
    type: UISocketMessageType;
    deviceId: string;
    siteId: string;
    states: UISocketDeviceStateUpdateState[];
}

export interface UISocketDeviceStateUpdateState {
    name: string;
    value: string;
}

export interface SetExecutionsMessage {
    executions: string[];
}

export interface UISocketServegoAccessGrantedMessage {
    type: UISocketMessageType;
    isServego: boolean;
    siteId: string;
}

export interface UISocketInterventionAccessGrantedMessage {
    type: UISocketMessageType;
    interventionSessionId: string;
    siteId: string;
}

export interface UISocketExecutionUpdateMessage {
    type: UISocketMessageType;
    job_id: string;
    status: ExecutionStatus;
    failureType?: string;
}

export interface UISocketNewInstallerMessageMessage {
    title: string;
    type: UISocketMessageType;
}

export interface UISocketDeviceRemoved {
    type: UISocketMessageType;
    deviceId: string;
    siteId: string;
}

export interface UISocketDeviceLabelUpdated {
    type: UISocketMessageType;
    deviceId: string;
    deviceLabel: string;
    siteId: string;
}

export interface UISocketDeviceCreated {
    type: UISocketMessageType;
    device: DeviceMS;
    siteId: string;
}

interface VideoCallEventBase {
    companyId: CompanyId;
    siteId: SiteId;
}

export interface UISocketCreatedMeetingVideoCallEvent extends VideoCallEventBase {
    type: VIDEO_CALL_EVENT_TYPE.MEETING_CREATED;
    id: VideoCallId;
    installerId: UserId;
    createdAt: Timestamp;
    url: string;
    ownerToken: string;
}

export interface UISocketJoinedParticipantVideoCallEvent extends VideoCallEventBase {
    type: VIDEO_CALL_EVENT_TYPE.JOINED_PARTICIPANT;
    userName: string;
    sessionId: string;
    isOwner: boolean;
}

export interface UISocketLeftParticipantVideoCallEvent extends VideoCallEventBase {
    type: VIDEO_CALL_EVENT_TYPE.LEFT_PARTICIPANT;
    userName: string;
    sessionId: string;
    isOwner: boolean;
}

export interface UISocketLeaveVideoCallEvent extends VideoCallEventBase {
    type: VIDEO_CALL_EVENT_TYPE.LEAVE_CALL;
}

export interface UISocketCreatedMeetingVideoCallEventMessage {
    type: UISocketMessageType.DATA;
    videoCallEvent: UISocketCreatedMeetingVideoCallEvent;
}

export interface UISocketJoinedParticipantVideoCallEventMessage {
    type: UISocketMessageType.DATA;
    videoCallEvent: UISocketJoinedParticipantVideoCallEvent;
}

export interface UISocketLeftParticipantVideoCallEventMessage {
    type: UISocketMessageType.DATA;
    videoCallEvent: UISocketLeftParticipantVideoCallEvent;
}

export interface UISocketLeaveVideoCallEventMessage {
    type: UISocketMessageType.DATA;
    videoCallEvent: UISocketLeaveVideoCallEvent;
}
