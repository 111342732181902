import { Injectable } from '@angular/core';
import DailyIframe, {
    DailyCall,
    DailyEventObjectParticipant,
    DailyEventObjectParticipantLeft,
    DailyEventObjectParticipants,
    DailyEventObjectTrack,
    DailyParticipant,
} from '@daily-co/daily-js';
import { Observable, Subject } from 'rxjs';
import { VIDEO_CALL_EVENT_TYPE, VideoCallEvent } from '../models/video-call-event';
import {
    Participant,
    PARTICIPANT_TYPE,
    ParticipantTrackKind,
    ParticipantTracks,
} from '../models/video-call-participant';
import { VideoCallProvider } from './VideoCallProvider';

@Injectable()
export class DailyVideoCallProvider implements VideoCallProvider {
    private videoCallEventSubject = new Subject<VideoCallEvent>();
    private callObject: DailyCall | undefined;

    getVideoCallEvents(): Observable<VideoCallEvent> {
        return this.videoCallEventSubject.asObservable();
    }

    emitVideoCallEvent(event: VideoCallEvent): void {
        this.videoCallEventSubject.next(event);
    }

    initializeVideoCall(url: string, token: string, userName: string = 'Installer'): void {
        this.callObject = DailyIframe.getCallInstance() || DailyIframe.createCallObject();

        this.callObject
            .on('joined-meeting', this.handleJoinedMeeting)
            .on('participant-joined', this.handleParticipantJoined)
            .on('track-started', this.handleTrackStarted)
            .on('track-stopped', this.handleTrackStopped)
            .on('participant-left', this.handleParticipantLeft)
            .on('left-meeting', this.handleLeftMeeting);

        this.callObject.join({
            userName,
            url,
            token,
        });
    }

    leaveVideoCall(): void {
        if (this.callObject) {
            this.callObject.leave();
            this.callObject.destroy();
            this.emitVideoCallEvent({ type: VIDEO_CALL_EVENT_TYPE.LEAVE_CALL });
        }
    }

    destroy(): void {
        this.callObject
            ?.off('joined-meeting', this.handleJoinedMeeting)
            .off('participant-joined', this.handleParticipantJoined)
            .off('track-started', this.handleTrackStarted)
            .off('track-stopped', this.handleTrackStopped)
            .off('participant-left', this.handleParticipantLeft)
            .off('left-meeting', this.handleLeftMeeting);
    }

    private handleJoinedMeeting = (e: DailyEventObjectParticipants): void => {
        const localParticipant = this.formatParticipant(e.participants.local);
        this.emitVideoCallEvent({
            type: VIDEO_CALL_EVENT_TYPE.JOINED_PARTICIPANT,
            participant: localParticipant,
        });
    };

    private handleParticipantJoined = (e: DailyEventObjectParticipant): void => {
        const participant = this.formatParticipant(e.participant);
        this.emitVideoCallEvent({ type: VIDEO_CALL_EVENT_TYPE.JOINED_PARTICIPANT, participant });
    };

    private handleTrackStarted = (e: DailyEventObjectTrack): void => {
        const tracks = this.getParticipantTracks(e.participant, e.track);
        this.emitVideoCallEvent({
            type: VIDEO_CALL_EVENT_TYPE.TRACK_STARTED,
            participantTracks: tracks,
        });
    };

    private handleTrackStopped = (e: DailyEventObjectTrack): void => {
        const tracks = this.getParticipantTracks(e.participant, e.track);
        this.emitVideoCallEvent({
            type: VIDEO_CALL_EVENT_TYPE.TRACK_STOPPED,
            participantTracks: tracks,
        });
    };

    private handleParticipantLeft = (e: DailyEventObjectParticipantLeft): void => {
        const participant = this.formatParticipant(e.participant);
        this.emitVideoCallEvent({ type: VIDEO_CALL_EVENT_TYPE.LEFT_PARTICIPANT, participant });
    };

    private handleLeftMeeting = (): void => {
        this.callObject.destroy();
    };

    private formatParticipant(participant: DailyParticipant): Participant {
        return {
            videoStream: undefined,
            audioStream: undefined,
            userName: participant.user_name,
            type: this.getParticipantType(participant),
            id: participant.session_id,
        };
    }

    private getParticipantTracks(participant: DailyParticipant, track: MediaStreamTrack): ParticipantTracks {
        return {
            participantType: this.getParticipantType(participant),
            video: participant.tracks.video.persistentTrack,
            audio: participant.tracks.audio.persistentTrack,
            kind: track.kind as ParticipantTrackKind,
        };
    }

    private getParticipantType(participant: DailyParticipant) {
        return participant.owner ? PARTICIPANT_TYPE.OWNER : PARTICIPANT_TYPE.GUEST;
    }
}
