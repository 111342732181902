import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NamedValueData } from '../../StateData';
import { StateEditorComponent } from '../StateEditorComponent';

export interface NamedValueEditorConfig {
    translationKey: string;
    possibleValues: number[] | string[];
}

@Component({
    selector: 'app-named-value-state-editor',
    standalone: true,
    imports: [TranslateModule],
    templateUrl: './named-value-editor.component.html',
    styleUrls: ['./named-value-editor.component.scss'],
})
export class NamedValueEditorComponent implements OnInit, StateEditorComponent<NamedValueData, NamedValueEditorConfig> {
    @Input({ required: true }) value: NamedValueData;
    @Input({ required: true }) config: NamedValueEditorConfig;
    @Output() valueChange = new EventEmitter<NamedValueData>();
    selectedValue: NamedValueData;

    ngOnInit(): void {
        this.selectedValue = this.value;
    }

    onUserInput(value: NamedValueData): void {
        this.selectedValue = value;
        this.valueChange.emit(value);
    }

    getItemTranslationKey(itemValue: number | string): string {
        let translationSuffix = `${itemValue}`;
        if (typeof itemValue === 'string') {
            translationSuffix = itemValue.toUpperCase().replace(/ /g, '_');
        }
        return `${this.config.translationKey}_${translationSuffix}`;
    }

    isValid(): boolean {
        return true;
    }

    isChecked(itemValue: NamedValueData): boolean {
        return `${itemValue}` === `${this.selectedValue}`;
    }
}
