import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import type { SiteAssignation } from '../../models/SiteAssignation';

class SiteAssignationActions {
    static readonly LOAD_PENDING_SITE_ASSIGNATIONS = 'LOAD_PENDING_SITE_ASSIGNATIONS';
    static readonly LOAD_PENDING_SITE_ASSIGNATION = 'LOAD_PENDING_SITE_ASSIGNATION';
    static readonly REMOVE_PENDING_SITE_ASSIGNATION = 'REMOVE_PENDING_SITE_ASSIGNATION';
}

export const loadPendingSiteAssignations = createAction(
    SiteAssignationActions.LOAD_PENDING_SITE_ASSIGNATIONS,
    props<{ siteAssignations: SiteAssignation[] }>(),
);

export const addPendingSiteAssignation = createAction(
    SiteAssignationActions.LOAD_PENDING_SITE_ASSIGNATION,
    props<{ siteAssignation: SiteAssignation }>(),
);

export const deletePendingSiteAssignation = createAction(
    SiteAssignationActions.REMOVE_PENDING_SITE_ASSIGNATION,
    props<{ gatewayId: string }>(),
);

export const SiteAssignationsPageActions = createActionGroup({
    source: 'Site Assignations',
    events: {
        opened: emptyProps(),
    },
});
