import { createActionGroup, props } from '@ngrx/store';
import { SiteId } from '../../../site-detail/models/Site';
import { VideoCall, VideoCallStatus } from '../../models/video-call';

export const VideoCallsActions = createActionGroup({
    source: 'VideoCalls',
    events: {
        videoCallsLoaded: props<{ videoCalls: VideoCall[] }>(),
        videoCallStarted: props<{ siteId: SiteId; videoCall: VideoCall }>(),
        videoCallStopped: props<{ siteId: SiteId }>(),
        videoCallStatusUpdated: props<{ siteId: SiteId; status: VideoCallStatus }>(),
    },
});
