export type ParticipantType = (typeof PARTICIPANT_TYPE)[keyof typeof PARTICIPANT_TYPE];

export const PARTICIPANT_TYPE = {
    OWNER: 'owner',
    GUEST: 'guest',
} as const;

export type Participant = {
    videoStream: MediaStream | undefined;
    audioStream: MediaStream | undefined;
    userName: string;
    type: ParticipantType;
    id: string;
};

export type ParticipantTrackKind = 'video' | 'audio';

export type ParticipantTracks = {
    participantType: ParticipantType;
    video: MediaStreamTrack;
    audio: MediaStreamTrack;
    kind: ParticipantTrackKind;
};

export type VideoParticipants = { [key in ParticipantType]: Participant };
