export const triggerStateMapping = new Map([
    [1, 'nb_of_double_power_cut'],
    [2, 'nb_of_obstacle_in_roll'],
    [3, 'nb_of_obstacle_in_unroll'],
    [4, 'nb_of_obstacle_in_roll'],
    [5, 'nb_of_obstacle_in_unroll'],
    [6, 'nb_of_stops_caused_by_thermal_protection'],
    [7, 'nb_of_stops_caused_by_thermal_protection'],
    [9, 'nb_of_double_power_cut'],
    [10, 'battery_level'],
    [11, 'nb_of_paired_1w_sensors_out_of_order'],
    [13, 'nb_cycles'],
    [15, 'nb_opening_obstacle_detections'],
    [16, 'nb_global_cycles'],
    [17, 'nb_closing_obstacle_detections'],
    [18, 'last_photocells_self_test_status'],
    [19, 'last_safety_edge_self_test_status'],
    [20, 'last_wicket_door_self_test_status'],
    [21, 'settings_lock_status'],
    [24, 'nb_global_obstacle_detections_3s'],
    [25, 'nb_global_cycles_3s'],
    [26, 'nb_of_swicth_from_battery_ok_to_battery_low'],
    [27, 'nb_of_swicth_from_battery_ok_to_battery_low'],
    [28, 'nb_of_obstacle_in_roll'],
    [29, 'nb_of_obstacle_in_unroll'],
    [30, 'nb_of_stops_caused_by_thermal_protection'],
    [31, 'nb_of_double_power_cut'],
    [32, 'feedback_name'],
    [33, 'feedback_name'],
    [34, 'nb_obstacle_zair_open'],
    [35, 'nb_movements_to_ventilation_error'],
    [36, 'rel_current_mode'],
    [37, 'rel_current_mode'],
    [38, 'uel_current_mode'],
    [39, 'uel_current_mode'],
    [40, 'nb_of_uel_reached_in_counting_mode'],
    [41, 'nb_of_eld_tcs_in_unroll_at_uel'],
    [43, 'nb_obstacles'],
    [44, 'core:BatteryLevelState'],
    [45, 'Diagnostic.ThermalStopCounter'],
]);
