import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ToasterJoinVideoCallMessageContent } from '../../../shared/models/Toaster';
import { AppState } from '../../../shared/store/app-state';
import { removeToasterMessage } from '../../../site-detail/store/actions/ui';

@Component({
    selector: 'app-video-call-toaster-item',
    standalone: true,
    imports: [TranslateModule],
    templateUrl: './video-call-toaster-item.component.html',
    styleUrl: './video-call-toaster-item.component.scss',
})
export class VideoCallToasterItemComponent {
    @Input() content: ToasterJoinVideoCallMessageContent;
    @Input() messageId: string;

    constructor(private store: Store<AppState>) {}

    onClose() {
        this.store.dispatch(removeToasterMessage({ id: this.messageId }));
    }
}
