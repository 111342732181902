import { Participant, ParticipantTracks } from './video-call-participant';

export enum VIDEO_CALL_EVENT_TYPE {
    MEETING_CREATED = 'meeting_created',
    JOINED_PARTICIPANT = 'joined_participant',
    LEFT_PARTICIPANT = 'left_participant',
    TRACK_STARTED = 'track_started',
    TRACK_STOPPED = 'track_stopped',
    LEAVE_CALL = 'leave_call',
}

export type VideoCallEvent =
    | JoinedParticipantVideoCallEvent
    | LeftParticipantVideoCallEvent
    | TrackStartedVideoCallEvent
    | TrackStoppedVideoCallEvent
    | LeaveVideoCallEvent;

export type JoinedParticipantVideoCallEvent = {
    type: VIDEO_CALL_EVENT_TYPE.JOINED_PARTICIPANT;
    participant: Participant;
};

export type LeftParticipantVideoCallEvent = {
    type: VIDEO_CALL_EVENT_TYPE.LEFT_PARTICIPANT;
    participant: Participant;
};

export type TrackStartedVideoCallEvent = {
    type: VIDEO_CALL_EVENT_TYPE.TRACK_STARTED;
    participantTracks: ParticipantTracks;
};

export type LeaveVideoCallEvent = {
    type: VIDEO_CALL_EVENT_TYPE.LEAVE_CALL;
};

export type TrackStoppedVideoCallEvent = {
    type: VIDEO_CALL_EVENT_TYPE.TRACK_STOPPED;
    participantTracks: ParticipantTracks;
};
