const protocolProfileToProductTypeMapping = {
    axovia_200_3s_io_v1: 'access 3s',
    axovia_200_3s_io_v2: 'access 3s',
    axovia_3s_io_v1: 'access 3s',
    axovia_3s_io_v2: 'access 3s',
    dexxo_pro_io: 'access 3s',
    dexxo_pro_io_fermetures: 'access 3s',
    dexxo_pro_io_futurol: 'access 3s',
    elixo_3s_io_v1: 'access 3s',
    elixo_3s_io_v2: 'access 3s',
    invisio_3s_io_v1: 'access 3s',
    invisio_3s_io_v2: 'access 3s',
    ixengo_3s_io_v1: 'access 3s',
    ixengo_3s_io_v2: 'access 3s',
    rollixio_io_v1: 'access 3s',
    dexxo_compact_1000_io: 'access smart',
    dexxo_compact_600_io: 'access smart',
    dexxo_compact_750_io: 'access smart',
    dexxo_smart_1000_io: 'access smart',
    dexxo_smart_800_io: 'access smart',
    elixo_smart_io: 'access smart',
    metro_smart_1000_io: 'access smart',
    metro_smart_800_io: 'access smart',
    moto_compact_1000_io: 'access smart',
    moto_compact_600_io: 'access smart',
    moto_compact_750_io: 'access smart',
    rollixo_smart_io: 'access smart',
    roly_smart_io: 'access smart',
    altea_50_solar_io: 'awning',
    control_unit_pergola: 'awning',
    maestria_io: 'awning',
    pergola_io_cqfd: 'awning',
    pergola_gibus_io: 'awning',
    pergola_io: 'awning',
    pergola_louver: 'awning',
    pergola_rgs_io: 'awning',
    pergola_screen_io: 'awning',
    awning_slim_receiver_io: 'awning',
    slim_receiver_pergola_io: 'awning',
    screen_slim_receiver_io: 'awning',
    smoove_uno_awning: 'awning',
    smoove_uno_interior_blind: 'awning',
    smoove_uno_screen: 'awning',
    sunea_40_io: 'awning',
    sunea_40_solar_io: 'awning',
    sunea_50_csi_io: 'awning',
    sunea_io_cqfd: 'awning',
    sunea_io_v1: 'awning',
    sunea_screen_io_cqfd: 'awning',
    sunea_screen_io_v1: 'awning',
    sunilus_io_cqfd: 'awning',
    sunilus_io_v1: 'awning',
    sunilus_screen_io_cqfd: 'awning',
    sunilus_screen_io_v1: 'awning',
    receiver_io_valance: 'awning',
    evb_100_tubular_io: 'evb',
    evb_slim_receiver_variation: 'evb',
    evb_slim_receiver_variation_io_wp: 'evb',
    j4_io: 'evb',
    j4_io_protect: 'evb',
    j4s_io_protect: 'evb',
    smoove_uno_evb: 'evb',
    actuo_40_io: 'interior',
    optuo_40_io: 'interior',
    sonesse_40_io: 'interior',
    amy_lighting_receiver_io: 'receivers',
    heating_receiver_io_dimming: 'receivers',
    heating_receiver_on_off: 'receivers',
    izymo_2x_lighting_receiver_io: 'receivers',
    izymo_dimmable: 'receivers',
    izymo_on_off: 'receivers',
    lighting_receiver_on_off: 'receivers',
    lighting_receiver_io_variation: 'receivers',
    on_off_plug_io: 'receivers',
    rgb_led_receiver: 'receivers',
    tuneable_white_led_receiver: 'receivers',
    white_led_receiver: 'receivers',
    intellitag_sliding: 'sensors',
    intellitag_tilt: 'sensors',
    sunis_wirefree_io: 'sensors',
    sunis_wirefree_io_2: 'sensors',
    sunteis_io: 'sensors',
    thermis_v1: 'sensors',
    thermis_v2: 'sensors',
    thermostat_io: 'sensors',
    thermostatic_valve_io: 'sensors',
    altus_50_io: 'shutter',
    altus_rs_io: 'shutter',
    amy_shutter_receiver_io: 'shutter',
    ilimo_50_io: 'shutter',
    izymo_rs_micromodule: 'shutter',
    izymo_shutter_receiver_io: 'shutter',
    odm_50_solar_io_high_torque: 'shutter',
    odm_50_solar_io_low_torque: 'shutter',
    oximo_40_io: 'shutter',
    oximo_solar_io: 'shutter',
    oximo_redesign_io: 'shutter',
    oximo_io_cqfd: 'shutter',
    oximo_io_v1: 'shutter',
    oximo_s_auto_io: 'shutter',
    oximo_s_io_v1: 'shutter',
    rs100_rre_io: 'shutter',
    rs100_solar_io_high_torque: 'shutter',
    rs100_solar_io_low_torque: 'shutter',
    sso_rs100_io_hybrid: 'shutter',
    sso_rs100_io_phase1: 'shutter',
    sso_rs100_io_sans_ppp: 'shutter',
    sso_rs100_io_air: 'shutter',
    slim_receiver_io: 'shutter',
    smoove_uno_io_curtain: 'shutter',
    smoove_uno_io_rs: 'shutter',
    smoove_uno_io_window: 'shutter',
    solus_50_io: 'shutter',
    upsylo_solar_io: 'shutter',
    sliding_air: 'window',
    sliding_air_v1_v2: 'window',
};

export default protocolProfileToProductTypeMapping;
