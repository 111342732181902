import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { SiteId } from '../../../site-detail/models/Site';
import { VIDEO_CALL_STATUS, VideoCall } from '../../models/video-call';
import { VideoCallsActions } from '../actions/videoCall';

export type VideoCallsState = Record<SiteId, VideoCall>;

export const VIDEO_CALLS_INITIAL_STATE: VideoCallsState = {};

export default createReducer(
    VIDEO_CALLS_INITIAL_STATE,

    on(VideoCallsActions.videoCallsLoaded, (state, { videoCalls }): VideoCallsState => {
        if (!(videoCalls instanceof Array)) {
            return state;
        }

        return videoCalls.reduce((updatedVideoCallsRecord, videoCall: VideoCall) => {
            updatedVideoCallsRecord[videoCall.siteId] = {
                ...videoCall,
                status: videoCall.status ?? VIDEO_CALL_STATUS.INVITATION_SENT,
            };
            return updatedVideoCallsRecord;
        }, {} as VideoCallsState);
    }),

    on(VideoCallsActions.videoCallStarted, (state, { siteId, videoCall }): VideoCallsState => {
        if (state[siteId]) {
            return state;
        }

        return {
            ...state,
            [siteId]: { ...videoCall, status: VIDEO_CALL_STATUS.INVITATION_SENT },
        };
    }),

    on(VideoCallsActions.videoCallStopped, (state, { siteId }): VideoCallsState => {
        if (!state[siteId]) {
            return state;
        }

        const videoCallsCopy: Record<SiteId, VideoCall> = { ...state };

        delete videoCallsCopy[siteId];

        return videoCallsCopy;
    }),

    on(VideoCallsActions.videoCallStatusUpdated, (state, { siteId, status }): VideoCallsState => {
        if (!state[siteId]) {
            return state;
        }

        return {
            ...state,
            [siteId]: {
                ...state[siteId],
                status,
            },
        };
    }),
);

export const getVideoCallsState = createFeatureSelector<VideoCallsState>('video-calls');
